import './Card.css';

export default function Card( props ) {	
  return (
    <div className="card-content">
      <section className="uk-background-cover uk-flex uk-flex-center uk-flex-middle" style={{ backgroundImage: `url(${props.data.picture})` }}>
        <div className="uk-overlay uk-overlay-primary">
          <p className="uk-text-uppercase uk-text-lead">{props.data.title}</p>
          <p className="uk-text-bold">{props.data.description}</p>
          <a className="uk-button uk-button-default" href={props.data.link}>
            Más información
          </a>
        </div>
      </section>
    </div>
  );
}
