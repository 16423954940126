import Card from '../Card/Card';

export default function App( props ) {
  return (
    <main>
      <div className="uk-grid uk-grid-collapse uk-child-width-expand@s uk-text-center">
        <Card data={props.blocks.la_inesperada} />
      </div>
      
      <div className="uk-grid uk-grid-collapse uk-child-width-expand@s uk-text-center">
        <Card data={props.blocks.costalisa} />
        <Card data={props.blocks.el_cartero} />
      </div>
    </main>
  );
}
